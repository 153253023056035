<template>
  <div class="hero is-fullheight" id="reset">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered vcenteded">
          <div class="column is-4">
            <figure class="avatar">
              <img src="@/assets/logo.png" />
            </figure>
            <p>
              Restablecer contraseña
            </p>
            <div class="box">
              <form @submit.prevent="recoverPassword">
                <div class="field">
                  <div class="control has-icons-right">
                    <input
                      class="input"
                      :type="!showPassword ? 'password' : 'text'"
                      required
                      name="password"
                      placeholder="Contraseña"
                      autofocus=""
                      autocomplete="off"
                      v-model="password"
                    />
                    <a class="icon is-small is-right" @click="viewPassword()">
                      <i class="far fa-eye" v-if="!showPassword"></i>
                      <i class="far fa-eye-slash" v-else></i>
                    </a>
                  </div>
                </div>
                <p :class="{ passValid: isValid, passNotValid: !isValid }">{{ validPassword }}</p>
                <div class="field">
                  <div class="control has-icons-right">
                    <input
                      class="input"
                      :type="!showRepeatedPassword ? 'password' : 'text'"
                      name="password2"
                      placeholder="Repetir contraseña"
                      autofocus=""
                      autocomplete="off"
                      v-model="repeatedPassword"
                    />
                    <a class="icon is-small is-right" @click="viewRepeatedPassword()">
                      <i class="far fa-eye" v-if="!showRepeatedPassword"></i>
                      <i class="far fa-eye-slash" v-else></i>
                    </a>
                  </div>
                </div>
                <p :class="{ passMatch: isMatch, passNotMatch: !isMatch }">{{ passwordMatch }}</p>
                <button
                  :disabled="!isDisabled"
                  :class="{ 'is-loading': loading }"
                  class="button is-block is-info is-medium is-fullwidth"
                >
                  Restablecer contraseña
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapMutations } from 'vuex'

export default {
  name: 'ResetPassword',
  props: ['token'],
  data () {
    return {
      recoveryToken: this.token,
      password: null,
      repeatedPassword: null,
      isValid: false,
      isMatch: false,
      button: false,
      regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/,
      showPassword: false,
      showRepeatedPassword: false,
      loading: false
    }
  },
  computed: {
    ...mapState({ authentication: state => state.authentication }),
    passwordMatch () {
      if (this.password && this.repeatedPassword) {
        if (this.password !== this.repeatedPassword) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isMatch = false
          return 'Las contraseñas no coinciden'
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isMatch = true
          return 'Las contraseñas coinciden'
        }
      }
      return null
    },
    validPassword () {
      if (this.password) {
        if (this.regex.test(this.password)) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isValid = true
          return 'La contraseña es válida'
        } else {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.isValid = false
          return 'La contraseña debe tener un número (0-9), al menos una letra minúscula (a-z) y mayúscula (A-Z), un carácter especial (@#$%&!) y 6 caracteres de largo.'
        }
      } else {
        return null
      }
    },
    // eslint-disable-next-line vue/return-in-computed-property
    isDisabled () {
      if (this.isValid && this.isMatch) {
        return !this.button
      }
    }
  },
  methods: {
    ...mapMutations(['setAuthenticationValue']),
    ...mapActions(['resetPass']),
    recoverPassword () {
      const data = {
        token: this.recoveryToken,
        password: this.password
      }
      this.loading = true
      this.resetPass(data)
        .then(resp => {
          this.loading = false
          if (resp) {
            this.$router.push('/login')
          }
        })
        .catch(err => this.$router.push(`/${err}`))
    },
    viewPassword () { this.showPassword = !this.showPassword },
    viewRepeatedPassword () { this.showRepeatedPassword = !this.showRepeatedPassword }
  }
}
</script>
<style scoped>
a.icon{
    cursor: pointer;
    pointer-events: auto !important;
  }
#reset {
  background-color: #27384e;
}
.avatar {
  margin-bottom: 50px;
}
.login {
  align-items: center;
  justify-content: center;
}
.box {
  background: transparent;
  box-shadow: none;
  padding: 40px;
}
.box .button {
  margin-top: 50px;
  background: #2c79b6;
  font-size: 1.1rem;
}
.field input { font-size: 0.75rem; padding: 1.20rem 0.5rem; }

.hero-body {
  padding: 0rem 1.5rem !important;
}
.link,
.link:focus,
.link:active,
.link:visited {
  color: #ffffff;
}
p {
  color: #ffffff;
}

.passMatch {
  color: green;
  font-size: 13px;
}

.passNotMatch {
  color: red;
  font-size: 13px;
  }

.passValid {
  color: green;
  font-size: 13px;
  margin-top: -8px;
  margin-bottom: 3px;
}
.passNotValid {
  color: red;
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 3px;
  }

</style>
